// Kiosk API of Kiosk Pro Plus (9.3.8627)

if (typeof(___kp_executeURL) !== "function")
{
	// Common JS-ObjC-Bridge API:
	function ___kp_executeURL(url)
	{
		var iframe = document.createElement("IFRAME");
		iframe.setAttribute("src", url);
		document.documentElement.appendChild(iframe);
		iframe.parentNode.removeChild(iframe);
		iframe = null;
	}
	window.___kp_executeURL = ___kp_executeURL;
}

// Kiosk Version API:
if (typeof(kp_VersionAPI_requestFullVersion) !== "function")
{
	function kp_VersionAPI_requestFullVersion(callback)
	{
		___kp_executeURL("kioskpro://kp_VersionAPI_requestFullVersion?" + encodeURIComponent(callback));
	}
	window.kp_VersionAPI_requestFullVersion = kp_VersionAPI_requestFullVersion;
}


if (typeof(kp_VersionAPI_requestMainVersion) !== "function")
{
	function kp_VersionAPI_requestMainVersion(callback)
	{
		___kp_executeURL("kioskpro://kp_VersionAPI_requestMainVersion?" + encodeURIComponent(callback));
	}
	window.kp_VersionAPI_requestMainVersion = kp_VersionAPI_requestMainVersion;
}


if (typeof(kp_VersionAPI_requestBuildNumber) !== "function")
{
	function kp_VersionAPI_requestBuildNumber(callback)
	{
		___kp_executeURL("kioskpro://kp_VersionAPI_requestBuildNumber?" + encodeURIComponent(callback));
	}
	window.kp_VersionAPI_requestBuildNumber = kp_VersionAPI_requestBuildNumber;
}


if (typeof(kp_VersionAPI_requestProductName) !== "function")
{
	function kp_VersionAPI_requestProductName(callback)
	{
		___kp_executeURL("kioskpro://kp_VersionAPI_requestProductName?" + encodeURIComponent(callback));
	}
	window.kp_VersionAPI_requestProductName = kp_VersionAPI_requestProductName;
}


if (typeof(kp_VersionAPI_requestProductNameWithFullVersion) !== "function")
{
	function kp_VersionAPI_requestProductNameWithFullVersion(callback)
	{
		___kp_executeURL("kioskpro://kp_VersionAPI_requestProductNameWithFullVersion?" + encodeURIComponent(callback));
	}
	window.kp_VersionAPI_requestProductNameWithFullVersion = kp_VersionAPI_requestProductNameWithFullVersion;
}


// Kiosk Accessibility API:
if (typeof(kp_AccessibilityAPI_isVoiceOverRunning) !== "function")
{
	function kp_AccessibilityAPI_isVoiceOverRunning(callback)
	{
		___kp_executeURL("kioskpro://kp_AccessibilityAPI_isVoiceOverRunning?" + encodeURIComponent(callback));
	}
	window.kp_AccessibilityAPI_isVoiceOverRunning = kp_AccessibilityAPI_isVoiceOverRunning;
}


// Identification API:
if (typeof(kp_requestKioskId) !== "function")
{
	function kp_requestKioskId(callback)
	{
		___kp_executeURL("kioskpro://kp_requestKioskId?" + encodeURIComponent(callback));
	}
	window.kp_requestKioskId = kp_requestKioskId;
}


if (typeof(kp_Identification_getGroupIDs) !== "function")
{
	function kp_Identification_getGroupIDs()
	{
		___kp_executeURL("kioskpro://kp_Identification_getGroupIDs");
	}
	window.kp_Identification_getGroupIDs = kp_Identification_getGroupIDs;
}


// File API:
if (typeof(writeToFile) !== "function")
{
	function writeToFile(fileName,data,callback)
	{
		___kp_executeURL("kioskpro://writeToFile?" + encodeURIComponent(fileName) + "&" + encodeURIComponent(data) + "&" + encodeURIComponent(callback));
	}
	window.writeToFile = writeToFile;
}


if (typeof(fileExists) !== "function")
{
	function fileExists(filename,callback)
	{
		___kp_executeURL("kioskpro://fileExists?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback));
	}
	window.fileExists = fileExists;
}


if (typeof(deleteFile) !== "function")
{
	function deleteFile(filename,callback)
	{
		___kp_executeURL("kioskpro://deleteFile?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback));
	}
	window.deleteFile = deleteFile;
}


if (typeof(kp_FileAPI_renameFile) !== "function")
{
	function kp_FileAPI_renameFile(oldFilename,newFilename,callback)
	{
		___kp_executeURL("kioskpro://kp_FileAPI_renameFile?" + encodeURIComponent(oldFilename) + "&" + encodeURIComponent(newFilename) + "&" + encodeURIComponent(callback));
	}
	window.kp_FileAPI_renameFile = kp_FileAPI_renameFile;
}


if (typeof(kp_FileAPI_base64FromFile) !== "function")
{
	function kp_FileAPI_base64FromFile(filename,callback)
	{
		___kp_executeURL("kioskpro://kp_FileAPI_base64FromFile?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback));
	}
	window.kp_FileAPI_base64FromFile = kp_FileAPI_base64FromFile;
}


// Photo & Video API:
if (typeof(saveScreenToPng) !== "function")
{
	function saveScreenToPng(filename,x,y,width,height,callback)
	{
		___kp_executeURL("kioskpro://saveScreenToPng?" + encodeURIComponent(filename) + "&" + encodeURIComponent(x) + "&" + encodeURIComponent(y) + "&" + encodeURIComponent(width) + "&" + encodeURIComponent(height) + "&" + encodeURIComponent(callback));
	}
	window.saveScreenToPng = saveScreenToPng;
}


if (typeof(kp_PhotoVideo_setCameraType) !== "function")
{
	function kp_PhotoVideo_setCameraType(shouldUseFrontCamera,callback)
	{
		___kp_executeURL("kioskpro://kp_PhotoVideo_setCameraType?" + encodeURIComponent(shouldUseFrontCamera) + "&" + encodeURIComponent(callback));
	}
	window.kp_PhotoVideo_setCameraType = kp_PhotoVideo_setCameraType;
}


if (typeof(kp_PhotoVideo_getCameraType) !== "function")
{
	function kp_PhotoVideo_getCameraType(callback)
	{
		___kp_executeURL("kioskpro://kp_PhotoVideo_getCameraType?" + encodeURIComponent(callback));
	}
	window.kp_PhotoVideo_getCameraType = kp_PhotoVideo_getCameraType;
}


if (typeof(takePhotoToFile) !== "function")
{
	function takePhotoToFile(filename,callback)
	{
		___kp_executeURL("kioskpro://takePhotoToFile?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback));
	}
	window.takePhotoToFile = takePhotoToFile;
}


if (typeof(kp_PhotoVideo_takePhotoToFileResize) !== "function")
{
	function kp_PhotoVideo_takePhotoToFileResize(filename,callback,photoWidthPxls,photoHeightPxls)
	{
		___kp_executeURL("kioskpro://kp_PhotoVideo_takePhotoToFileResize?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback) + "&" + encodeURIComponent(photoWidthPxls) + "&" + encodeURIComponent(photoHeightPxls));
	}
	window.kp_PhotoVideo_takePhotoToFileResize = kp_PhotoVideo_takePhotoToFileResize;
}


if (typeof(takePhotoWithCountdownToFile) !== "function")
{
	function takePhotoWithCountdownToFile(filename,callback,counter,message,showingTime)
	{
		___kp_executeURL("kioskpro://takePhotoWithCountdownToFile?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback) + "&" + encodeURIComponent(counter) + "&" + encodeURIComponent(message) + "&" + encodeURIComponent(showingTime));
	}
	window.takePhotoWithCountdownToFile = takePhotoWithCountdownToFile;
}


if (typeof(kp_PhotoVideo_takePhotoWithCountdownToFileResize) !== "function")
{
	function kp_PhotoVideo_takePhotoWithCountdownToFileResize(filename,callback,counter,message,showingTime,photoWidthPxls,photoHeightPxls)
	{
		___kp_executeURL("kioskpro://kp_PhotoVideo_takePhotoWithCountdownToFileResize?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback) + "&" + encodeURIComponent(counter) + "&" + encodeURIComponent(message) + "&" + encodeURIComponent(showingTime) + "&" + encodeURIComponent(photoWidthPxls) + "&" + encodeURIComponent(photoHeightPxls));
	}
	window.kp_PhotoVideo_takePhotoWithCountdownToFileResize = kp_PhotoVideo_takePhotoWithCountdownToFileResize;
}


if (typeof(takeVideoToFile) !== "function")
{
	function takeVideoToFile(filename,callback)
	{
		___kp_executeURL("kioskpro://takeVideoToFile?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback));
	}
	window.takeVideoToFile = takeVideoToFile;
}


if (typeof(kp_PhotoVideo_takeVideoWithCountdown) !== "function")
{
	function kp_PhotoVideo_takeVideoWithCountdown(filename,callback,intervalBeforeStart,recordingTime,showRecordingCountdownTimer,successMessage)
	{
		___kp_executeURL("kioskpro://kp_PhotoVideo_takeVideoWithCountdown?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback) + "&" + encodeURIComponent(intervalBeforeStart) + "&" + encodeURIComponent(recordingTime) + "&" + encodeURIComponent(showRecordingCountdownTimer) + "&" + encodeURIComponent(successMessage));
	}
	window.kp_PhotoVideo_takeVideoWithCountdown = kp_PhotoVideo_takeVideoWithCountdown;
}


if (typeof(kp_PhotoVideo_takeVideoWithEndingByTouchingScreen) !== "function")
{
	function kp_PhotoVideo_takeVideoWithEndingByTouchingScreen(filename,callback,intervalBeforeStart,maxRecordingTime,messageDuringRecording,showRecordingTimer,successMessage)
	{
		___kp_executeURL("kioskpro://kp_PhotoVideo_takeVideoWithEndingByTouchingScreen?" + encodeURIComponent(filename) + "&" + encodeURIComponent(callback) + "&" + encodeURIComponent(intervalBeforeStart) + "&" + encodeURIComponent(maxRecordingTime) + "&" + encodeURIComponent(messageDuringRecording) + "&" + encodeURIComponent(showRecordingTimer) + "&" + encodeURIComponent(successMessage));
	}
	window.kp_PhotoVideo_takeVideoWithEndingByTouchingScreen = kp_PhotoVideo_takeVideoWithEndingByTouchingScreen;
}


// Memory & Privacy API:
if (typeof(kp_Browser_clearCookies) !== "function")
{
	function kp_Browser_clearCookies()
	{
		___kp_executeURL("kioskpro://kp_Browser_clearCookies");
	}
	window.kp_Browser_clearCookies = kp_Browser_clearCookies;
}


if (typeof(kp_Browser_clearCache) !== "function")
{
	function kp_Browser_clearCache()
	{
		___kp_executeURL("kioskpro://kp_Browser_clearCache");
	}
	window.kp_Browser_clearCache = kp_Browser_clearCache;
}


if (typeof(kp_Browser_clearLocalStorage) !== "function")
{
	function kp_Browser_clearLocalStorage()
	{
		___kp_executeURL("kioskpro://kp_Browser_clearLocalStorage");
	}
	window.kp_Browser_clearLocalStorage = kp_Browser_clearLocalStorage;
}


// Dropbox API:
if (typeof(kp_DBXSyncManager_sync) !== "function")
{
	function kp_DBXSyncManager_sync()
	{
		___kp_executeURL("kioskpro://kp_DBXSyncManager_sync");
	}
	window.kp_DBXSyncManager_sync = kp_DBXSyncManager_sync;
}


if (typeof(kp_DBXSyncManager_stopObservingChangesOfType) !== "function")
{
	function kp_DBXSyncManager_stopObservingChangesOfType(typeOfChanges)
	{
		___kp_executeURL("kioskpro://kp_DBXSyncManager_stopObservingChangesOfType?" + encodeURIComponent(typeOfChanges));
	}
	window.kp_DBXSyncManager_stopObservingChangesOfType = kp_DBXSyncManager_stopObservingChangesOfType;
}


if (typeof(kp_DBXSyncManager_startObservingChangesOfType) !== "function")
{
	function kp_DBXSyncManager_startObservingChangesOfType(typeOfChanges)
	{
		___kp_executeURL("kioskpro://kp_DBXSyncManager_startObservingChangesOfType?" + encodeURIComponent(typeOfChanges));
	}
	window.kp_DBXSyncManager_startObservingChangesOfType = kp_DBXSyncManager_startObservingChangesOfType;
}


if (typeof(kp_DBXSyncManager_getTypeOfObservingChanges) !== "function")
{
	function kp_DBXSyncManager_getTypeOfObservingChanges(callback)
	{
		___kp_executeURL("kioskpro://kp_DBXSyncManager_getTypeOfObservingChanges?" + encodeURIComponent(callback));
	}
	window.kp_DBXSyncManager_getTypeOfObservingChanges = kp_DBXSyncManager_getTypeOfObservingChanges;
}


// AirPrinter API:
if (typeof(kp_AirPrinter_requestStateOfSupporting) !== "function")
{
	function kp_AirPrinter_requestStateOfSupporting()
	{
		___kp_executeURL("kioskpro://kp_AirPrinter_requestStateOfSupporting");
	}
	window.kp_AirPrinter_requestStateOfSupporting = kp_AirPrinter_requestStateOfSupporting;
}


if (typeof(kp_AirPrinter_print) !== "function")
{
	function kp_AirPrinter_print()
	{
		___kp_executeURL("kioskpro://kp_AirPrinter_print");
	}
	window.kp_AirPrinter_print = kp_AirPrinter_print;
}


if (typeof(kp_AirPrinter_printPdf) !== "function")
{
	function kp_AirPrinter_printPdf(filename)
	{
		___kp_executeURL("kioskpro://kp_AirPrinter_printPdf?" + encodeURIComponent(filename));
	}
	window.kp_AirPrinter_printPdf = kp_AirPrinter_printPdf;
}


// Common Printer API:
if (typeof(print) !== "function")
{
	function print()
	{
		___kp_executeURL("kioskpro://print");
	}
	window.print = print;
}


// Idle Timer API:
if (typeof(kp_IdleTimer_fire) !== "function")
{
	function kp_IdleTimer_fire()
	{
		___kp_executeURL("kioskpro://kp_IdleTimer_fire");
	}
	window.kp_IdleTimer_fire = kp_IdleTimer_fire;
}


// KioWare API:
if (typeof(kp_KioWare_closeCurrentSession) !== "function")
{
	function kp_KioWare_closeCurrentSession()
	{
		___kp_executeURL("kioskpro://kp_KioWare_closeCurrentSession");
	}
	window.kp_KioWare_closeCurrentSession = kp_KioWare_closeCurrentSession;
}


if (typeof(kp_KioWare_registerNavigation) !== "function")
{
	function kp_KioWare_registerNavigation(url,pageTitle,callback)
	{
		___kp_executeURL("kioskpro://kp_KioWare_registerNavigation?" + encodeURIComponent(url) + "&" + encodeURIComponent(pageTitle) + "&" + encodeURIComponent(callback));
	}
	window.kp_KioWare_registerNavigation = kp_KioWare_registerNavigation;
}


// Zapier API:
if (typeof(kp_Zapier_sendNotificationToZap) !== "function")
{
	function kp_Zapier_sendNotificationToZap(urlOfZap,data,callback)
	{
		___kp_executeURL("kioskpro://kp_Zapier_sendNotificationToZap?" + encodeURIComponent(urlOfZap) + "&" + encodeURIComponent(data) + "&" + encodeURIComponent(callback));
	}
	window.kp_Zapier_sendNotificationToZap = kp_Zapier_sendNotificationToZap;
}


// Autonomous Single App Mode API:
if (typeof(kp_AutonomousSingleAppModeAPI_startSession) !== "function")
{
	function kp_AutonomousSingleAppModeAPI_startSession(silently)
	{
		___kp_executeURL("kioskpro://kp_AutonomousSingleAppModeAPI_startSession?" + encodeURIComponent(silently));
	}
	window.kp_AutonomousSingleAppModeAPI_startSession = kp_AutonomousSingleAppModeAPI_startSession;
}


if (typeof(kp_AutonomousSingleAppModeAPI_endSession) !== "function")
{
	function kp_AutonomousSingleAppModeAPI_endSession(silently)
	{
		___kp_executeURL("kioskpro://kp_AutonomousSingleAppModeAPI_endSession?" + encodeURIComponent(silently));
	}
	window.kp_AutonomousSingleAppModeAPI_endSession = kp_AutonomousSingleAppModeAPI_endSession;
}


// Geolocation API:
if (typeof(kp_Geolocation_requestLocation) !== "function")
{
	function kp_Geolocation_requestLocation(callback)
	{
		___kp_executeURL("kioskpro://kp_Geolocation_requestLocation?" + encodeURIComponent(callback));
	}
	window.kp_Geolocation_requestLocation = kp_Geolocation_requestLocation;
}


// Guided Access API:
if (typeof(kp_GuidedAccess_requestStatus) !== "function")
{
	function kp_GuidedAccess_requestStatus()
	{
		___kp_executeURL("kioskpro://kp_GuidedAccess_requestStatus");
	}
	window.kp_GuidedAccess_requestStatus = kp_GuidedAccess_requestStatus;
}


// Synthesized Speach API:
if (typeof(kp_Speech_speak) !== "function")
{
	function kp_Speech_speak(speechString,speechUtteranceConfig)
	{
		___kp_executeURL("kioskpro://kp_Speech_speak?" + encodeURIComponent(speechString) + "&" + encodeURIComponent(speechUtteranceConfig));
	}
	window.kp_Speech_speak = kp_Speech_speak;
}


if (typeof(kp_Speech_stop) !== "function")
{
	function kp_Speech_stop()
	{
		___kp_executeURL("kioskpro://kp_Speech_stop");
	}
	window.kp_Speech_stop = kp_Speech_stop;
}


if (typeof(kp_Speech_pause) !== "function")
{
	function kp_Speech_pause()
	{
		___kp_executeURL("kioskpro://kp_Speech_pause");
	}
	window.kp_Speech_pause = kp_Speech_pause;
}


if (typeof(kp_Speech_continue) !== "function")
{
	function kp_Speech_continue()
	{
		___kp_executeURL("kioskpro://kp_Speech_continue");
	}
	window.kp_Speech_continue = kp_Speech_continue;
}


if (typeof(kp_Speech_requestCurrentLanguageCode) !== "function")
{
	function kp_Speech_requestCurrentLanguageCode(callback)
	{
		___kp_executeURL("kioskpro://kp_Speech_requestCurrentLanguageCode?" + encodeURIComponent(callback));
	}
	window.kp_Speech_requestCurrentLanguageCode = kp_Speech_requestCurrentLanguageCode;
}


if (typeof(kp_Speech_requestListOfLanguageCodes) !== "function")
{
	function kp_Speech_requestListOfLanguageCodes(callback)
	{
		___kp_executeURL("kioskpro://kp_Speech_requestListOfLanguageCodes?" + encodeURIComponent(callback));
	}
	window.kp_Speech_requestListOfLanguageCodes = kp_Speech_requestListOfLanguageCodes;
}


if (typeof(kp_Speech_requestListOfVoiceIdentifiers) !== "function")
{
	function kp_Speech_requestListOfVoiceIdentifiers(callback)
	{
		___kp_executeURL("kioskpro://kp_Speech_requestListOfVoiceIdentifiers?" + encodeURIComponent(callback));
	}
	window.kp_Speech_requestListOfVoiceIdentifiers = kp_Speech_requestListOfVoiceIdentifiers;
}


// Set hooks are available:
window.kioskpro_hooks_available = 1;