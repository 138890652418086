require("./plugins.js");

// Elements
var $window,
    $body,
    $mapPage,
    $detailPage,
    $detailHeader,
    $detailCover;

// Mobile/browser config
var max_mobile_width   = 767;

var device_is_touch    = detectTouch(),
    browser_is_ie      = detectIE(),
    browser_is_iOS     = detectiOS(),
    browser_is_Android = detectAndroid();

// Transition events
var transitionEndEvents = "transitionend";

// Window size
var window_width,
    window_height,
    screen_is_mobile;

// Map
var map,
    mapImage,
    mapIcon,
    bounds,
    viewBounds,
    maxBounds,
    zoomAmount,
    zoomable;

    var leafletIconSize = "60"; // Needs to match @leaflet-icon-size in main.less

// FancyBox
var fancyOptions = {
    loop: true,
    infobar: false,
    autoFocus: true,
    backFocus: false,
    idleTime: 0,
    smallBtn: false,
    toolbar: true,
    arrows: false,
    touch: true,
    buttons: [
        "close"
    ]
};

// ScrollMagic & GSAP
var scroll_controller,
    cover_scene,
    cover_tween;

// Debug options
var debug_bounds = false;
var debug_points = false;
var debug_scroll = false;

// Kiosk Data
const kiosks = require("../../data/kiosks.json");
window.kiosks = kiosks;

const iconUrl = require("/src/svg/hotspot.svg");
window.iconUrl = iconUrl;


$(function() {


    /////////////////////// Init ////////////////////////////////

    // Make JQuery Variables //
    $window = $(window);
    $body   = $("body");
    $mapPage = $('#page-map');
    $detailPage = $('#page-detail');
    $detailHeader = $('#detail-header');
    $detailCover = $('#detail-cover');

    // Set various height/width/size values on load 
    initLayout();


    ////////////////// Fetch JSON Data ///////////////////////////
    var kiosk = kiosks.find(kiosk => {
        return kiosk.uid === window.kioskId;
    });
    kiosk = kiosk || kiosks[2];

    zoomable = kiosk.data.zoomable;


    //////////////// Ccreate FancyBox Templates ///////////////////
    fancyOptions.baseTpl  = $("#overlay_template").clone().html();
    fancyOptions.btnTpl = {
        close: $("#overlay_close").clone().html()
    };


    ///////////////// Render Kiosk Home Screen ///////////////////
    $('#map-header').html(Mustache.render(
        $('#map-header-template').html(),
        {
            title: kiosk.data.title,
            description: kiosk.data.description
        }
    ));

    ///////////////// Create the Image Map ///////////////////
    var mapDimensions = kiosk.data.image_map.dimensions;
    var mapHeight     = mapDimensions.height;
    var mapWidth      = mapDimensions.width;

    bounds     = [[0, 0], [mapHeight, mapWidth]];
    viewBounds = [[100, 100], [(mapHeight - 100), (mapWidth - 100)]];
    maxBounds  = [[-200, -200], [(mapHeight + 200), (mapWidth + 200)]];
    zoomAmount = 0.25;

    map = L.map('map', {
        attributionControl: false, // turn off leaflet attribution
        zoomControl: false, // Disable the zoom control so we can customize it later
        crs: L.CRS.Simple,  // Simple coordinate (y, x) system
        trackResize: true, 
        scrollWheelZoom: false, 
        bounceAtZoomLimits : false,
        preferCanvas: true,
        zoomSnap: zoomAmount,
        zoomDelta: zoomAmount,
        maxBounds: viewBounds,
        maxZoom: 10,
        minZoom: -10
    });

    mapImage = L.imageOverlay("./" + kiosk.data.image_map.url, bounds).addTo(map);

    // Prevent zooming if this kiosk is set as not zoomable
    if (zoomable == "No") {
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.dragging.disable();
        
    } else {
        L.control.zoom({
            position:'bottomright'
        }).addTo(map);
    }

    // Display bounding box rectangles (Debug)
    if (debug_bounds) {
        L.rectangle(bounds, {color: "#00ff00", weight: 1}).addTo(map);
        L.rectangle(viewBounds, {color: "#ffff00", weight: 1}).addTo(map);
        L.rectangle(maxBounds, {color: "#0000ff", weight: 1}).addTo(map);
    }

    var features = kiosk.data.points_of_interest.map(link => {
        var obj = {};
        if (link.point_of_interest && link.point_of_interest.id) {
            obj = {
                "type": "Feature",
                "properties": {
                    "code": link.point_of_interest.data.code,
                    "name": link.point_of_interest.data.name,
                    "id": link.point_of_interest.id
                },
                "geometry": {
                    "type": "Point",
                    "coordinates": [
                        mapDimensions.width * link.point_of_interest.data.x_coordinate,
                        mapDimensions.height * (1 - link.point_of_interest.data.y_coordinate)
                    ]
                },
                "data": link.point_of_interest.data
            };
        }
        return obj;
    });

    // Define the point of interest icons
    mapIcon = new L.divIcon({
        iconSize:   [leafletIconSize, leafletIconSize],
        iconAnchor: [leafletIconSize/2, leafletIconSize/2],
        html: '<span></span>'
    });
 
    L.geoJSON(features, {
        pointToLayer: function (feature, latlng) {
            return L.marker(latlng, {icon: mapIcon});
        },
        onEachFeature: onEachFeature
    }).addTo(map);

    setMapZoom();

    if (debug_points) {
        // Copy X/Y coordinates to the console for testing
        map.on('click', function(e) {
            L.tooltip({
                permanent: false,
                direction: 'center',
                className: 'text'
            })
            .setContent("[" + (e.latlng.lng / bounds[1][1]) + ", " + (1 - (e.latlng.lat / bounds[1][0])) + "]")
            .setLatLng(e.latlng)
            .addTo(map);
        });
    }


    ///////////////// Detail cover scroll effects ///////////////////
    scroll_controller = new ScrollMagic.Controller();


    ///////////////// Do things on window resize ///////////////////
    $window.resize(function() {
        initLayout();
        map.invalidateSize();
        setMapZoom();
    });


    // Magic Reset
    $window.on('touchstart', function (e) {
        if (e.touches.length > 4) window.location.reload();
    });

});


///////////////////////  Functions /////////////////////////////

// Show / Hide featurepage code

var inFeaturePageTransition = false;
var featurePageShowing = false;
var featureView = null;

function showFeaturePage(feature) {
    if (inFeaturePageTransition) {
        return;
    }
    inFeaturePageTransition = true;
    if (featurePageShowing) {
        return;
    }

    // assemble data for template
    let objects = feature.data.objects.map(object => {
            return object && object.object ? object.object : {};
        })
        .filter(object => {
            return !$.isEmptyObject(object);
        })
        .map(object => {
            let newObject = {}
            newObject["name"] = object.data.name || undefined;
            newObject["subtitle"] = object.data.subtitle || undefined;
            newObject["description"] = object.data.description || undefined;
            newObject["image"] = object.data.image ? $.extend({}, object.data.image) : undefined;

            if (newObject["image"] && newObject["image"]["thumb"]) {
                if (newObject["image"]["thumb"]["url"]) {
                    newObject["thumb"] = newObject["image"]["thumb"];
                } else {
                    newObject["thumb"] = $.extend({}, object.data.image);
                }
                delete newObject["image"]["thumb"];
            }

            return newObject;
        });
    let data = {
        title: objects.length ? objects[0].name : "Point of Interest",
        cover: objects.length ? objects[0].image.url : "",
        objects: objects
    };

    
    // Render detail header from template
    let headerTemplate = $('#detail-header-template').html();
    $detailHeader.html(Mustache.render(headerTemplate, data));

    $detailHeader.find('#detail-close').click(() => {
       hideFeaturePage();
    });

    // Render detail cover from template
    let coverTemplate = $('#detail-cover-template').html();
    $detailCover.html(Mustache.render(coverTemplate, data));

    // Render detail body from template
    let detailTemplate = $("#detail_template").html();
    $detailPage.html(Mustache.render(detailTemplate, data));

    // add fancybox
    $detailPage.fancybox($.extend({}, {
        selector : 'a.fancybox-link'
    }, fancyOptions));

    // after the page has fully tranisitioned on screen, do stuff
    $detailPage.on(transitionEndEvents, () => {
        $mapPage.toggleClass("off", true); // Hide the map
        $detailPage.off(transitionEndEvents);
        inFeaturePageTransition = false;
        featurePageShowing = true;
    });

    // Parallax scroll and fade the cover image on scroll

    // Create the scroll animation (tween)
    cover_tween = new TimelineMax()
        .add([TweenMax.to("#detail-cover .scroll",       0.10, {opacity:"0", ease: Linear.easeNone              })])
        .add([TweenMax.to("#detail-cover",               1.00, {opacity:"0", ease: Linear.easeNone, delay: -0.10})])
        .add([TweenMax.to("#detail-cover .image",        1.00, {y:"-10%", z:0.001, rotation:0.001, ease: Linear.easeNone, delay: -1.00})])
        .add([TweenMax.to("#detail-content .main-title", 0.25, {opacity:"0", ease: Linear.easeNone, delay: -0.85})])
        .add([TweenMax.to("#detail-header .fade",        0.25, {opacity:"1", ease: Linear.easeNone, delay: -0.85})]);

    // Create the scroll handler (scene), and attach the tween to it
    cover_scene = new ScrollMagic.Scene({triggerElement: "#detail-content .column", triggerHook: "onEnter", duration: "100%"})
        .on("enter", function (event) {
            if (debug_scroll) console.log("enter", event.scrollDirection);
        })
        .on("leave", function (event) {
            if (debug_scroll) console.log("leave", event.scrollDirection);
        })
        .setTween(cover_tween)
        .addTo(scroll_controller);

    if (debug_scroll) cover_scene.addIndicators()

    // wait for the images to be loaded before displaying the page
    $("#page-detail, #detail-cover").find(".image").imagesLoaded(
        { background: true },
        () => { 
            $detailPage.toggleClass("on", true);
            $detailHeader.toggleClass("on", true);
            $detailCover.toggleClass("on", true);
        }
    );
}

function hideFeaturePage(force) {
    if (inFeaturePageTransition) {
        return;
    }
    if (!featurePageShowing && !force) {
        return;
    }

    $detailPage.on(transitionEndEvents, () => {
        $detailHeader.find('#detail-close').off('click');
        $detailHeader.empty();
        $detailCover.empty();
        cover_scene.destroy(true);
        cover_tween.kill();
        $.fancybox.destroy();
        $detailPage.empty();
        $detailPage.off(transitionEndEvents);
        featurePageShowing = false;
    });
    
    $mapPage.toggleClass("off", false);
    $detailPage.toggleClass("on", false);
    $detailHeader.toggleClass("on", false);
    $detailCover.toggleClass("on", false);
}

// Set map zoom
function setMapZoom() {
    map.fitBounds(viewBounds);
    map.setMinZoom( map.getZoom() );
    map.setMaxZoom( map.getZoom() + (zoomAmount * 6) );
}

// Take action when feature markers are clicked
function onEachFeature(feature, layer) {

    // Add code numbers to the markers
    var text = L.tooltip({
        permanent: true,
        direction: 'center',
        className: 'label'
    })
    .setContent("" + feature.properties.code)
    .setLatLng(layer.getLatLng())
    .addTo(map);

    // Launch the detail page on tap
    layer.on('click', () => {
        setTimeout(() => {
            showFeaturePage(feature);
        })
    });

}

// Set various values and properties on load and resize
function initLayout() {
    window_height = $window.height();
    window_width  = $window.width();
    screen_is_mobile = detectMobile(max_mobile_width);
}
