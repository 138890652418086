// v1.0
var jQuery = require("./vendor/jquery-3.4.1.min.js");
window.$ = window.jQuery = jQuery;

require("./vendor/fancybox.js");
require("./vendor/imagesloaded.pkgd.js");

var Mustache = require("./vendor/mustache.js");
window.Mustache = Mustache;

var L = require("./vendor/leaflet.js");
window.L = L;

/*
var TimelineMax= require("./vendor/TweenMax.min.js");
window.TimelineMax  = TimelineMax;

var ScrollMagic = require("./vendor/ScrollMagic.min.js");
window.ScrollMagic  = ScrollMagic;

require("./vendor/animation.gsap.min.js");
require("./vendor/debug.addIndicators.min.js");
*/

require("./kiosk_functions.js");

window.kioskId = null;

function setKioskId(kioskId) {
  window.kioskId = kioskId;
  $.holdReady(false);
}
window.setKioskId = setKioskId;

$.holdReady(true);
kp_requestKioskId("setKioskId");

setTimeout(function() {
  if (window.kioskId === null) {
    window.kioskId = "local";
  }
  $.holdReady(false);
});

// Avoid `console` errors in browsers that lack a console.
(function() {
    var method;
    var noop = function noop() {};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());


// Detect if this has small screen, and is likely a mobile device
function detectMobile(mobile_width) {
    if (window.matchMedia("(max-width: " + (mobile_width -1) + "px)").matches) {
      // This is mobile, the viewport is less than 768px wide
      document.body.classList.add("is_mobile");
      return true;
    } else {
      // Probably not mobile, the viewport is >= than 768px wide
      return false;
    }
}
window.detectMobile = detectMobile;

// Detect if this is a touch device, which is not ncessarily the same as mobile device
export function detectTouch() {
    var is_touch_device = 'ontouchstart' in window || navigator.maxTouchPoints;
    if ( is_touch_device == true) {
        // This IS a touch device
        document.body.classList.add("is_touch");
        return true;
    } else {
        // This is not a touch device
        return false;
    }
}
window.detectTouch = detectTouch;

// Detect if this is an iOS device
function detectiOS() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
        // This IS an iOS device (supports iOS 2.0 and later)
        var v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
        document.body.classList.add("is_iOS");
        return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
    } else {
        // This is not an iOS device
        return false;
    }
}
window.detectiOS = detectiOS;

//Detect if this is an Android device 
function detectAndroid() {
    var ua = window.navigator.userAgent;
    var android = ua.indexOf("Android");
    if (android > 0) {
        // This IS an Android device 
        document.body.classList.add("is_android");
        return parseFloat(ua.slice(ua.indexOf("Android")+8));
    } else {
        return false;
    }
}
window.detectAndroid = detectAndroid;

// Detect IE, returns version of IE or false, if browser is not Internet Explorer
function detectIE() {
  var ua = window.navigator.userAgent;

  // Test values; Uncomment to check result …

  // IE 10
  // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';
  
  // IE 11
  // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';
  
  // Edge 12 (Spartan)
  // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';
  
  // Edge 13
  // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    document.body.classList.add("is_IE");
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    document.body.classList.add("is_IE");
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
window.detectIE = detectAndroid;

// https://stackoverflow.com/a/22279245
function unescapeHtml(safe) {
    return safe.replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");
}
window.unescapeHtml = unescapeHtml;